.price-intelligence-layout {
  width: 100%;
  margin: auto;
}

/************** Splash SCreen **************/
.pricing-splash {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #181f37;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing-slpash-img {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.pricing-splash-heading h1 {
  font-family: "Cinzel";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 369px;
  margin: 0px auto;
}

/************** Welcome Screen **************/
.pricing-entrence {
  width: 100%;
  background: #181f37;
  margin: auto;
  color: white;
  height: 100vh;
}

/* header css */
.pricing-entrence-header {
  height: 62px;
  background-color: #181f37;
  color: white;
  display: flex;
  align-items: center;
  padding: 0px 32px;
  justify-content: space-between;
}

.pricing-entrence-heading h1 {
  font-family: "Cinzel";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.pricing-entrence-content-img {
  max-height: calc(100vh - 385px);
  /* max-height: calc(100vh - 289px); */
  height: 100%;
  background-image: url("../assets/images/pricing/entrence_img1.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
}

@media (orientation:landscape) {
  .pricing-entrence-content-img {
    /* max-height: calc(100vh - 289px); */
    height: 100%;
    background-image: url("../assets/images/pricing/entrence_img1.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    /* align-items: flex-end; */
    justify-content: flex-end;
    min-height: 200px;
    flex-direction: column;
    margin: 0 auto;

  }

  .pricing-entrence-content-img:before {
    content: " ";
    background-image: linear-gradient(179.63deg,
        rgba(178, 188, 205, 0.28) 53.28%,
        rgba(24, 31, 55, 0.33) 65.77%,
        #181f37 91.9%);
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 1px;
    transform: translateY(1px);
    min-height: 200px;

  }

}

.pricing-entrence-content-img:before {
  content: " ";
  background-image: linear-gradient(179.63deg,
      rgba(178, 188, 205, 0.28) 53.28%,
      rgba(24, 31, 55, 0.33) 65.77%,
      #181f37 91.9%);
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 289px);
  height: 100%;
  top: 1px;
  transform: translateY(1px);
}

.price-manually {
  background: transparent !important;
  border-radius: 10px;
  border: none;
  font-style: normal;
  text-decoration: underline !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #b18503 !important;
  padding: 15px 32px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;

}

.price-manually-img {
  color: #b18503 !important;
}

.betaPrice {
  position: relative;
}
.betaPrice p{
  margin: 0;
  color: white;
  font-size: 10px;
  text-transform: uppercase;

}

.pricing-entrence-content-img h1, .pricing-entrence-banner-img h1, .betaPrice h1 {
  font-family: "Cinzel", serif;
  font-weight: 400;
  font-size: 44px;
  line-height: 40px;
  text-decoration-line: underline;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 9;
  margin: 0;
  padding-bottom: 40px;
}

.price-intelligence-text {
  padding-bottom: 0px !important;
}

@media (orientation:landscape) {
  .pricing-entrence-content-img h3 {
    padding-bottom: 90px;
  }

}

.pricing-entrence-content-bottom {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 21px;
}

.pricing-entrence-button {
  padding: 0px 32px;
}

button.pricing-entrence-content-btn.btn {
  background: #b18503;
  /* border-radius: 10px; */
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 15px 32px;
  width: 100%;
  height: 54px;
}

.pricing-entrence-content-bottom a {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: lowercase;
  color: #ffffff;
  margin: 0;
  text-decoration: none;
  transition: all 0.3s;
}

.pricing-entrence-content-bottom a:hover,
.pricing-entrence-content-bottom a:active {
  color: #b18503;
}

/**************First Time Email Password**************/
.pricing-userlogin {
  margin: auto;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}

.pricing-userlogin-content {
  padding: 0px 32px;
  min-height: calc(100vh - 64px);
  position: relative;
  padding-bottom: 20px;
}

.pricing-userlogin-content-form {
  height: calc(100vh - 136px);
  /* overflow-y: scroll; */
  margin-bottom: 10px;
}

@media (orientation:landscape) {
  .pricing-userlogin-content-form {
    height: auto;
    margin-bottom: 20px;
  }

  .pricing-userlogin-content-form a {
    display: block;
    margin: 0;
    padding-block: 53px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #182960;
  }
}

.pricing-userlogin-content-form h1 {
  padding: 86px 0px 80px 0px;
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #0d1949;
}

.pricing-userlogin-content-input {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.pricing-userlogin-content-input input {
  padding: 5px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #6a77a2;
  background: none;
  border: none;
  border-bottom: 1px solid #6a77a2;
  border-radius: 0;
}

.pricing-userlogin-content-input .form-floating input:focus,
.pricing-userlogin-content-input .form-floating input:hover,
.pricing-userlogin-content-input .form-floating input:active {
  box-shadow: none;
}

.pricing-userlogin-content-input input::placeholder {
  color: #6a77a2;
}

.pricing-userlogin-content-input .form-floating>label {
  padding: 1.5rem 0px !important;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #6a77a2;
}

.pricing-userlogin-content-input .form-floating>.form-control {
  padding: -0.5rem 0px !important;
}

.pricing-userlogin-content-input .form-floating input {
  padding: 20px 0px 0px 0px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #6a77a2;
}

.pricing-userlogin-content-input input:focus {
  border-bottom: 1px solid #b18503 !important;
  outline: none !important;
}

.pricing-userlogin-content-form a {
  display: block;
  margin: 0;
  padding-top: 53px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #182960;
}

.pricing-userlogin-content-btn .btn {
  background: #b18503;
  /* border-radius: 10px; */
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 15px 32px;
  width: 100%;
  /* height: 54px; */
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pricing-userlogin-content-btn .btn:disabled {
  opacity: 0.4;
  background: #b18503;
}

.visible-wrapper {
  position: absolute;
  right: 10px;
  bottom: 3px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visible-wrapper svg {
  width: 24px;
  height: 24px;
}

.pricing-userlogin-content-btn .btn:hover,
.pricing-userlogin-content-btn .btn:focus,
.pricing-userlogin-content-btn .btn:active {
  background: #b18503 !important;
}

.error_msg {
  border-bottom: 1px solid red;
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 2.3rem !important;
  padding-bottom: 0.625rem !important;
}

.gia-text .btn{
  text-transform: none;
}

/********** start-pricing *******/
.start-pricing {
  /* background-color: #181f37; */
  height: 100%;
}

.start-pricing-text .toggle {
  position: absolute;
  top: 0;
  color: #0d1949;
  right: 32px;
  font-size: 32px;
}

.start-pricing-content,
.pricing-entrence-content {
  height: calc(100vh - 64px);
  background-color: #181f37;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.start-pricing-content .container{
  max-width: 920px;
  width: 100%;
  margin: 10px auto;
  padding: 0px;
}

.start-pricing-banner {
  height: 220px;
}
.start-pricing-banner img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2.86px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.20);
}

.new-start-pricing {
  background-color: white !important;
  /* height: unset !important; */
}


.new-start-pricing .pricing-boxes {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

.new-start-pricing .pricing-boxes-gia {
  height: 435.601px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-start-pricing .about-price-boxes {
  padding: 22px 20px;
  margin-bottom: 80px;
}

.start-pricing-inpbox-new input {
  /* color: #6C757D !important; */
  border-bottom: 1px solid #6C757D !important;
}

.start-pricing-inpbox-new input::placeholder{
  color: #6C757D !important;
}

.new-start-pricing .start-pricing-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
  /* padding: 0px 20px; */
}

.new-start-pricing .start-pricing-header {
  /* padding: 0px 10px 0px 20px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  position: relative;
}
.new-start-pricing .start-pricing-header h3 {
  margin: 0;
  font-size: 26px;
  font-weight: 500;
  margin-top: -7px !important;
}

.start-pricing-menuIcon {
  background: none !important;
  border: none !important;
}

.update-start-pricing-form {
  gap:0px !important; 
}

.pricing-boxes-gia .pricing-boxes-para p {
  color: #0D1949;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.4px;
  max-width: 420px;
  margin: auto;
  margin-bottom: 20px;
}

.para-about {
  font-weight: 600;
  font-size: 16px;
}

.para-about p{
  margin: 0;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  margin: 0;
  text-align: justify;
}

.para-about-text {
  display: flex !important;
  flex-direction: column;
}

.about-header {
  display: none !important;
}

.start-pricing-header h3{
  color: #181F37;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  margin-top: -8px !important;
}

.start-pricing-header span{
  color: #0D1949;
font-family: Montserrat;
font-size: 10px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.4px;
text-transform: uppercase;
}

.visual-para {
color: black;
}

@media (orientation:landscape) {

  .start-pricing-content,
  .pricing-entrence-content {
    height: 100vh;
    background-color: #181f37;
    position: relative;
    display: flex;
    flex-direction: column;
  }

}

.pricing-entrence-content {
  padding-bottom: 55px;
  justify-content: space-between;
}

.pricing-entrence-content .pricing-entrence-content-img h1 {
  font-size: 35px;
}

.start-pricing-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  align-items: center;
  /* padding: 0px 20px; */
  background: #181f37;
}

.start-pricing-buttons {
  padding: 0px 10px !important
}

.start-pricing-inpbox {
  display: flex;
}

.start-pricing-inpbox input {
 
  background: none;
  border: none;
  width: calc(100% - 58px);
  border-bottom: 1px solid white;
 
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  border-radius: 0;
  border-left: 0;

  border-right: 0;
  border-top: 0;
  border-color: #6a77a2;
  caret-color: #ffbf00;
  color: #0d1949;
  font-size: 18px;
  letter-spacing: .56px;
  line-height: 32px;
 

}

.gia-number-input input {
  width: calc(100% - 0px);

}


.start-pricing-inpbox input::placeholder {
  font-weight: 500;
}

.start-pricing-inpbox input:hover,
.start-pricing-inpbox input:active,
.start-pricing-inpbox input:focus {
  outline: none;
}

.start-pricing-inpbox input::placeholder {
  color: white;
}

.start-pricing-inpbox .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 32px;
  background: #b18503;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 0;
  border: none;
  padding: 0;
}

.start-pricing-inpbox .btn:hover,
.start-pricing-inpbox .btn:active,
:not(.go-btn.btn-check)+.go-btn.btn:active,
.start-pricing-inpbox .btn:focus,
button.pricing-entrence-content-btn.btn:hover,
button.pricing-entrence-content-btn.btn:active,
button.pricing-entrence-content-btn.btn:focus {
  background: #b18503;
}

.start-pricing-inpbox .btn:disabled {
  opacity: 0.4;
  background: #b18503;
}

.start-pricing-formbox {
  width: 100%;
  color: #ffffff;
  max-width: 370px;
}

.start-pricing-formbox p {
  /* padding-top: 23px; */
  padding-top: 12px; 
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: capitalize;
}

.gia-summary-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 20px; */
}

.gia-summary-data p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
}

.start-pricing-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.start-pricing-loader p {
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #ffffff;
}

.spinner {
  width: 100px;
  height: 100px;
  display: grid;
  border: 9.5px solid #0000;
  border-radius: 50%;
  border-color: #b18503 #0000;
  animation: spinner-rotate 1s infinite linear;
}

.spinner::after {
  margin: 8.9px;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}

/***************pricing-disclaimer**********/
.pricing-disclaimer {
  height: 100vh;
  background: #ffffff;
}

.disclaimer-entrence-content {
  position: relative;
  height: calc(100vh - 64px);
}

.pricing-disclaimer-container {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  padding: 0px 20px;
}

.pricing-disclaimer-content {
  background-color: white;
  padding: 0px 32px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  max-width: 565px;
  width: 100%;
  margin: auto;
}

.pricing-disclaimer-content h1 {
  margin: 0;
  padding-top: 85px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #182960;
}

.pricing-disclaimer-content p {
  margin: 0;
  padding-top: 23px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.005em;
  color: #182960;
  padding-bottom: 30px;
}

.pricing-disclaimer-content a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: lowercase;
  color: #b18503;
  text-decoration: none;
}

.pricing-disclaimer-content h1 {
  color: #FF0000;
}

.pricing-disclaimer-text p a {
  font-size: 16px;
  line-height: 29px;
  font-weight: 500;
}

.pricing-disclaimer-formgroup .form-check {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #182960;
  gap: 8px;
}

.pricing-userlogin-content-btn.pricing-disclaimer-btn {
  padding-bottom: 20px;
  max-width: 100%;

}

.pricing-disclaimer-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
 
}


.desktop-view-disclaimer-pricing {
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%, 20%); */
  padding: 45px 66px;
}

.desktop-view-disclaimer-pricing h1 {
  padding-top: 20px;
}

.desktop-view-disclaimer-pricing p {
  padding-bottom: 60px;
}

.desktop-view-disclaimer-pricing .pricing-userlogin-content-btn .btn {
  width: 90%;
  margin: auto;
  text-transform: capitalize;
  border-radius: 0px;
}

.desktop-view-disclaimer-pricing .pricing-userlogin-content-btn .custom-box {
  padding: 40px 0px;

}
.disclaimer-modal .modal-content{
border: none;
}

.disclaimer-modal.modal-backdrop.show {
  background: #171F37CC;
  opacity: 1;
}

.custom-box {
  padding-bottom: 10px;
}

[type="checkbox"] {
  opacity: 0;
}

[type="checkbox"]+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #182960;
}

[type="checkbox"]+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  height: 18px;
  width: 18px;
  outline: 2.5px solid #0d1949;
  border-radius: 1px;
  background-color: #ffffff;
}

[type="checkbox"]:checked+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  height: 18px;
  width: 18px;
  outline: 2.5px solid #0d1949;
  border-radius: 1px;
  background-color: #ffffff;
}

[type="checkbox"]:checked+label::after {
  content: "";
  position: absolute;
  left: -1.55px;
  top: 1px;
  display: inline-block;
  width: 7px;
  height: 13px;
  border: 1px solid #0d1949;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-radius: 0px;
  margin: 0px 15px 5px 7px;
}

/****************pricing-giasummuary**************/
.pricing-giasummuary {
  height: 100%;
}

.pricing-giasummuary-content {
  height: calc(100vh - 64px);
  background-color: #ffffff;
  padding: 0px 32px;
  position: relative;
}

.pricing-giasummuary-text h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
  padding-top: 85px;
}

.pricing-giasummuary-text p {
  margin: 0;
  padding-top: 9px;
}

.pricing-giasummuary-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 157px;
}

/* ******************** Info Modal ******************** */

.info-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 19px 0 50px;
  max-height: 63vh;
  overflow-y: auto;
  padding-right: 6px;
  scroll-behavior: smooth;
}

.info-modal .back-modal-body .info-content p {
  padding: 0;
}

/* ************** Divider ***************** */

.divider {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.005em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.divider::before,
.divider::after {
  position: absolute;
  content: "";
  width: 27px;
  height: 2px;
  background-color: rgb(255 255 255 / 15%);
  top: 50%;
  transform: translateY(-50%);
}

.divider::before {
  left: -47px;
}

.divider::after {
  right: -47px;
}

.start-pricing-loader.text-light {
  min-height: 258px;
}

.pricing-entrence-content-img.start-pricing-text .menu-toggle.btn {
  position: absolute;
  top: 13px;
  right: 29px;
}

.start-pricing-text h3 {
  color: #FFFFFF;
  margin: 0 auto;
  z-index: 2;
  padding-bottom: 40px;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 2%;

}

.confidential-mobile-text {
  margin: 0 auto;
  z-index: 2;
  padding-top: 30px;
  /* padding-bottom: 20px; */
  /* font-size: 18px; */
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 2%;
  color: #BDBDBD;
  text-transform: uppercase;
  text-align: center;

}

.confidential-desktop-text-update {
  padding-top: 24px !important;
  font-size: 12px !important;
}

.confidential-desktop-text {
  margin: 0 auto;
  z-index: 2;
  padding-top: 40px;
  /* padding-bottom: 20px; */
  font-size: 24px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 2%;
  color: #BDBDBD;
  text-transform: uppercase;
  text-align: center;

}

.start-pricing-Updatedbox .back_btn {
text-decoration: underline;
}


.start-menu-pricing {
  position: absolute;
  right: 55%;
  width: 215.284px;
  border-radius: 0px 0px 3px 3px;
  background: #FFF;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.20)
}

.back-contain-btn {
  background: none !important;
  border: none !important;
}

.back-text {
  color: #0D1949;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 8.391px;
}

.start-menu-pricing-data {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
  height: 40px;
  border-bottom: 1px solid #B9BBC2;
  padding: 15px;
}

.start-menu-pricing-data:hover {
background-color: #fffbee;
border-radius: 0px 0px 3px 3px;
}

.start-menu-pricing-data p{
  margin: 0;
}


.start-menu-link, .start-menu-link:hover  {
  display: flex;
  color: #080810;
  gap: 15px;
  text-decoration: none;
}

/* css for scroll */
.pricing-userlogin-content-form::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.pricing-userlogin-content-form::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.pricing-userlogin-content-form::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.pricing-userlogin-content-form::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sliderEmail {
  font-size: 14px;
}

.b2b-only-text {
  font-size: 14px;
  padding-bottom: 45px;
  text-align: center;


}

/* form group  */

.check_custom {
  padding-top: 15px;
}

.check_custom [type="checkbox"] {
  position: absolute;
}

.check_custom [type="checkbox"]+label::before {
  background-color: transparent;
  border: 2px solid #BF8F02;
  border-radius: 2px;
}

.check_custom [type="checkbox"]:checked+label::after {
  border: 1px solid #BF8F02;
  /* border: 1px solid #0d1949; */
  border-width: 0 3px 3px 0;
  width: 6px;
  height: 11px;
  left: -0.55px;
  top: 3px;
}

.check_custom [type="checkbox"]+label {
  color: white;
}

.defaultIdu_layout {
  background: #181f37 !important;
}

.dafaultIdu_outer {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.dafaultIdu_outer>div {
  display: flex;
  color: white;
  align-items: center;
  gap: 20px;
}

.dafaultIdu_outer p {
  margin: 0px;
  font-size: 14px;
  text-transform: uppercase;
}

.defaultIdu_pd {
  padding: 0px 40px !important;
  display: flex;
  justify-content: center;
}

.dafaultIdu_content_wd {
  width: 162px;
}

.custom_box_home [type="checkbox"]+label::before {
  outline: 2px solid #b18503;
  background: transparent;

}

.custom_box_home [type="checkbox"]:checked+label::after {
  border: 1px solid #b18503;
  border-width: 0 3px 3px 0;
}

.custom_box_home [type="checkbox"]+label {
  color: #b18503;
}

.gia_result_updated {
  margin-top: 32px;
  gap: 20px;
}

.check_value_summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back_btn {
  background: none !important;
  border: none !important;
  color: #b18503 !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  position: relative;
}

.back_btn img {
  transform: rotate(180deg);
  position: absolute;
  top: 12px;
  left: 2px;

}

.form-wrapper_pricing {
  gap: 30px !important;
}


/* for desktop view */

.desktop_pricing .select-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.desktop-pricing-text {
  color: #182960;
  text-align: center;
  font-family: Cinzel;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding-bottom: 50px;
}

.giaReportNum {
  color: var(--texts, #0D1949);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  text-transform: capitalize;
}

.form-wrapper_blue p {
  color: white;
  font-size: 12px;
  max-width: 125px;
  width: 100%;
  text-align: center;
}

.Idu_popup p {
  color: #182960;
  font-size: 16px;
  text-transform: uppercase;
}

.form-wrapper_blue {
  background-color: #182960;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 405px;
  padding: 20px 12px;
  /* margin-right: 25px; */
   margin-right: 7px;

}

.form-wrapper_outer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.desktop_pricing .form-input-radiowrapper {
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 50px;
}

.calculate_btn {
  height: 84px;
  align-items: flex-end !important;

}

.calculate_btn .new_toggle_box {
  max-width: 304px !important;
  width: 100% !important;
}

.calculate_btn .pricing-userlogin-content-btn.pricing-disclaimer-btn {
  padding: 0px;
}

.shapes_container {
  display: flex;
  gap: 20px;
  max-width: 240px;
  width: 100%;
  /* background: red; */
}

.shapes-wd .shape_radiobtn {
  min-width: 20px;
}

.true_face_wd {
  margin-left: -13px;
}

.true_face_wd_without_round {
  margin-left: -6px;
}

.shapes_face_mr {
  margin-right: 5px;
}

.shapes_face_mr_mbl {
  margin-right: 10px;
}

.shapes_face_mr_without_round {
  margin-right: 15px;
}

.shapes_face_mrtc {
  margin-right: 9px;
}

.shapes_face_mrtc_without_round_mb {
  margin-right: 18px;
}

.shape_radiobtn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  /* width: 20px; */
}

.shape_radiobtn .shape_box p {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-wrap: nowrap;
}

.text-decoration {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-wrap: nowrap;

}

.shape_radiobtn .shape_box {
  height: 90px;
  display: flex;
  align-items: flex-end;
}

.shape_radiobtn .shape_box>div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shape_radiobtn .shape_box3 img {
  height: 61px;
}

.shape_radiobtn .shape_box2 img {
  height: 56px;
}

.shape_radiobtn .shape_box1 img {
  height: 50px;
}

.shape_radiobtn .shape_box0 img {
  height: 46px;
}

.shape_radiobtn [type="radio"] {
  border: 5px solid white;
  box-shadow: 0 0 0 1px #000000;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}

.shape_radiobtn [type="radio"]:checked {
  background-color: #0D1949;
  box-shadow: 0 0 0 1px #000000;
  border: 3px solid white;
}

.shape_radiobtn [type="radio"]:focus {
  border-color: white;
}


.form-input-radiowrapper .shape_radiobtn [type="radio"] {
  border: 5px solid #182960;
  box-shadow: 0 0 0 1px #ffffff;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #182960;
  transition: all ease-in 0.2s;
}

.form-input-radiowrapper .shape_radiobtn [type="radio"]:checked {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
  border: 3px solid #182960;
}

.form-input-radiowrapper .shape_radiobtn [type="radio"]:focus {
  border-color: #182960;
}

.Idu_popup_container .form-input-radiowrapper .shape_radiobtn [type="radio"] {
  border: 5px solid white;
  box-shadow: 0 0 0 1px #182960;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: white;
  transition: all ease-in 0.2s;
}

.Idu_popup_container .form-input-radiowrapper .shape_radiobtn [type="radio"]:checked {
  background-color: #182960;
  box-shadow: 0 0 0 1px #182960;
  border: 3px solid white;
}

.Idu_popup_container .form-input-radiowrapper .shape_radiobtn [type="radio"]:focus {
  border-color: white;
}

.form-input-newwrapper .new_toggle_box {
  max-width: 230px;
  width: 100%;
  /* padding-right: 10px; */
}

.form-input-newwrapper .new_toggle_box .single-pair-checkbox {
  max-width: unset;
  height: 61px;
  /* border-radius: 9px; */
  border-color: #6a77a2;
  color: #182960;
}

.form-input-newwrapper .new_toggle_box .single-pair-checkbox .single-pair-toggle {
  background: #182960;
  /* border-radius: 8px; */
  max-width: 114px;
  width: 100%;
  height: 48px;
}

.form-input-radiowrapper input.form-control {
  background: no-repeat;
  color: #939CBC;
  font-size: 14px;
}

.pricing-boxes-gia .pricing-boxes-para span {
  display: none;
}

.new-start-pricing .about-price-boxes .mob-arrow-down {
  display: none;
}

.new-header {
  padding: 0px 0px 40px;
}

.result-header{
  max-width: 598.809px;
  margin: auto;
}

.pricing-header{
padding: 0px 58px;
}


.new-start-pricing .pricing-userlogin-content-btn .btn {
border-radius: 0px;
}

@media screen and (max-width:991px){
  .new-start-pricing .start-pricing-container {
         padding: 0px 20px;
         gap:16px;
  }
}


@media screen and (max-width:768px) {
  .start-pricing-form {
    padding: 0px 0px;
  }
  .desktop-view-disclaimer-pricing {
    /* transform: translate(-50%,5%) !important; */
    padding: 45px 25px;
  }
  .pricing-boxes-gia .pricing-boxes-para p,.start-pricing-formbox p {
    font-size: 14px;
    max-width: 275px;
    /* line-height: 20px; */
  }

  .pricing-boxes-gia .pricing-boxes-para span {
    color: #0D1949;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 39px; */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    display: block;
  }

  .start-pricing-header span {
    display: none;
  }

  .new-start-pricing .start-pricing-header h3, .search-history h3 {
    font-size: 22px;
  }

  .new-start-pricing .build_price_toggle .single-pair-checkbox .single-pair-slide {
    width: 250px;
  }

  .new-start-pricing .single-pair-checkbox {
height: 48px;
  }

  .new-start-pricing .build_price_toggle .single-pair-checkbox {
    min-width: 270px;
    min-height: 48px;
  }

  .new-start-pricing .build_price_toggle .single-pair-check:checked+.single-pair-checkbox .single-pair-slide .single-pair-toggle {
    transform: translateX(126px) !important;
  }

  .new-start-pricing .build_price_toggle .single-pair-checkbox .single-pair-toggle {
    width: 132.28px;
    min-height: 38px !important;
    height: 38px;
  }

  .new-start-pricing .about-price-boxes {
    padding: 22px 30px;
  }


  .new-header {
    padding: 0px 0px;
  }
  .pricing-header{
    max-width: 88%;
  }
  .result-header{
    max-width: 96%;
    margin: auto;
  }

  /* .new-start-pricing .start-pricing-container {
    padding: 0px 20px;
  } */
  .new-start-pricing .start-pricing-header {
    padding: 0px 10px 0px 20px;
  }

  .new-start-pricing .about-price-boxes .mob-arrow-down {
    display: block;
    rotate: 180deg;
  }
  
.new-start-pricing .about-price-boxes .desk-arrow-down {
display: none;
}
}


@media screen and (max-width:376px) {
  .pricing-entrence-content-img {
      max-height: calc(100vh - 289px);
  }
}
@media screen and (max-width:480px) {
  .pricing-entrence-header {
    justify-content: center;
  }
}