.profileCard {
    width: 350px;
    border:1px solid #acacac;
   
    cursor: pointer;
    
}

.name{
    color:#224458;
    font-weight: 600;
}
.joined{
    font-size: 14px;
}

.updateButton{
    background: none;
    border-radius: 0px;
  border: 1px solid #b18503;
  box-shadow: none;
  color: #b18503;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
 
}

.changebutton{
    border-bottom: 1px solid #4f6bf9;
    color: #4f6bf9;
    height:fit-content;
}
.updateButton:hover{
    background: none !important;
    border-radius: 0px;
  border: 1px solid #715604 !important;
  box-shadow: none;
  color: #715604 !important;
}

.passwordlavel{
    font-size: 13px;
    padding-top: 12px;
    margin: 0px;
}