.back-modal .modal-dialog {
  max-width: 362px;
  margin: 0px auto;
}
.back-modal .modal-content {
  border: none;
  border-radius: 10px;
}
.close-btn {
  display: flex;
  justify-content: flex-end;
  padding: 17px;
}

.close-btn .btn-primary {
  background: none;
  padding: 0;
  border: none;
}
.close-btn .btn:hover {
  background: none;
  border: none;
}
.close-btn .btn:first-child:active {
  background: none;
  border: none;
  box-shadow: none;
}
.back-modal .modal-body {
  padding: 8px 40px 70px 40px;
}
.back-modal-body h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #182960;
  margin: 0;
}
.back-modal-body p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.005em;
  color: #182960;
  margin: 0;
  padding: 10px 0px 90px 0px;
}
.action-grid {
  display: flex;
  gap: 22px;
}
.action-grid .btn {
  background: none;
  padding: 0;
  border: none;
  max-width: 80px;
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #b18503;
}
.action-grid .btn:hover,
.action-grid .btn:active,
:not(.modal-back-btn.btn-check)+.modal-back-btn.btn:active,
.action-grid .btn:first-child:active {
  background: none;
  border: none;
  box-shadow: none;
  color: #b18503;
}
