.pricing-giasummuary .header nav.navbar.navbar-light {
  min-height: 56px !important;
  padding: 0px 32px;
}

.header nav.navbar.navbar-light {
  background: #181f37;
  padding: 12px 33px;
}
.header .navbar-toggler:focus {
  box-shadow: none;
}
.header .navbar-toggler-icon {
  background: url("../../assets/images/pricing/menu-icon.svg");
}
.header-back {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header-back .btn-primary {
  background: none;
  padding: 0;
  border: none;
}
.header-back .btn:hover {
  background: none;
  border: none;
}
.header-back .btn:first-child:active {
  background: none;
  border: none;
  box-shadow: none;
}
.header-back p {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.menu-toggle.btn,
.menu-toggle.btn:hover,
.menu-toggle.btn:active,
:not(.menu-toggle.btn.btn-check) + .menu-toggle.btn.btn:active,
.menu-toggle.btn:focus,
.menu-toggle.btn:focus-visible {
  background: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pi-sidebar.offcanvas.offcanvas-end {
  background: #181f37;
  color: #fff;
  max-width: 460px;
  width: 100%;
}

.pi-sidebar.offcanvas .btn-close {
  background-image: url(../../assets/images/pricing/cross-icon-white.svg);
  opacity: 1;
  width: 32px;
  height: 32px;
  background-size: contain;
  padding: 0;
  margin: 0;
}

.pi-sidebar.offcanvas .btn-close:focus,
.pi-sidebar.offcanvas .btn-close:focus-visible {
  box-shadow: none;
  outline: 0;
}

.pi-sidebar.offcanvas .offcanvas-header {
  justify-content: flex-end;
}

.pi-sidebar.offcanvas .offcanvas-body {
  padding: 0;
}

.sidebar-nav-list.accordion {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.sidebar-nav-list.accordion .accordion-item {
  background: #181f37;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.005em;
  color: #c6c9d3;
  border-radius: 0 !important;
}

.sidebar-nav-list.accordion .accordion-item .accordion-button {
  background: #262e49;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 25px 77.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
}

.sidebar-nav-list.accordion .accordion-body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sidebar-nav-list.accordion .accordion-body span {
  padding: 16px;
  border-bottom: 1px solid #262e49;;
  padding-left: 90px;
  cursor: pointer;
}
.sidebar-nav-list.accordion .accordion-body span:last-child {
  border: none;
}

.sidebar-nav-list.accordion .accordion-button::after{
  background-image: url(../../assets/images/pricing/down-arrow-white.svg);
  background-size: 100%;
  width: 12px;
  height: 8px;
}

.sidebar-nav-list.accordion .accordion-button:not(.collapsed)::after{
  background-image: url(../../assets/images/pricing/down-arrow-white.svg);
  transform: rotate(180deg);
}