.info-modal .back-modal-body p {
    padding: 19px 0px 50px 0px;
}
.info-modal.fade {
    background: transparent;
}

.bg_remove_modal .modal-dialog.modal-dialog-centered {
    max-width: 520px;
}
.bg_remove_modal  h4{
    font-size: 14px;
}

.bg_remove_modal .close-btn .btn-primary {
    position: absolute;
    top: -20px;
    background: #b18503;
    padding: 10px;
    border-radius: 50%;
    right: -20px;
    cursor: pointer;
}

.bg_remove_modal .modal-body {
    padding: 8px 40px 40px 40px;
}


.err-modal h4{
  text-transform: none !important;
}
.err-modal p{
    padding: 0px !important;
}

.err-modal p a{
   color : #b18503 !important;
   text-decoration: underline;
   
}


@media only screen and (max-width: 600px) {
    .bg_remove_modal .modal-content{
        margin: 0 10px;
        
}
.bg_remove_modal .close-btn .btn-primary {
    right: -8px;
}
.bg_remove_modal h4 {
    font-size: 12px;
    
}
.bg_remove_modal .close-btn {
    padding: 10px;
}










.bg_remove_modal .modal-body {
    padding: 8px 20px 20px 20px;
}
}