.history-delete-modal .modal-header {
  border: 0;
  padding: 0;
}
.history-delete-modal .modal-body,
.disclaimer-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.history-delete-modal .modal-body {
  padding: 40px;
}
.history-delete-modal .modal-body p {
  font-family: Montserrat;
  color: #1b2653;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  margin: 0;
}

.history-delete-modal .modal-header .btn-close {
  background-color: #bf8f02;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  right: -8px;
  top: -8px;
}

.history-delete-modal .modal-header {
  border-bottom: none;
}

.history-delete-modal-body {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.delete-modal-btn {
  display: flex;
  justify-content: space-between;
}

.delete-modal-btn .btn {
  width: 49%;
}

.no-outline-btn.btn {
    background: #bf8f02;
    border: none;
    border-radius: 0;
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
    transition: all 0.3s;
  }
  
  .no-outline-btn.btn:hover,
  .no-outline-btn.btn:focus,
  .no-outline-btn.btn:focus-visible,
  .no-outline-btn.btn-check:checked + .no-outline-btn.btn,
  .no-outline-btn.btn.active,
  .no-outline-btn.btn.show,
  .no-outline-btn.btn:first-child:active,
  :not(.no-outline-btn.btn-check) + .no-outline-btn.btn:active {
    background: #a67d01;
    outline: 0;
    box-shadow: none;
  }
  
  .no-outline-btn.btn:disabled,
  .no-outline-btn.btn.disabled {
    background: #bf8f02;
    border-color: #bf8f02;
  }
  
  .outline-btn.btn,
  .outline-btn.btn:disabled {
    background: transparent;
    height: 40px;
    border: 2px solid #bf8f02;
    border-radius: 0;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 1px;
    text-align: center;
    color: #bf8f02;
    transition: all 0.3s;
  }
  
  .outline-btn.btn:hover,
  .outline-btn.btn:focus,
  .outline-btn.btn:focus-visible,
  .outline-btn.btn-check:checked + .outline-btn.btn,
  .outline-btn.btn.active,
  .outline-btn.btn.show,
  .outline-btn.btn:first-child:active,
  :not(.outline-btn.btn-check) + .outline-btn.btn:active {
    border-color: #a67d01;
    background: transparent;
    outline: 0;
    box-shadow: none;
    color: #a67d01;
  }
  .scroll-up {
    position: fixed;
    right: 2%;
    bottom: 50px;
    z-index: 500;
    cursor: pointer;
  }

.search-history {
  max-width: 920px;
  width: 100%;
  margin:0px auto 20px;
}

.search-history h3 {

  color: #1b2653;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  margin-bottom: 20px;

}

.search-data {
  align-items: center;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(14, 30, 62, 0.08);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 23px 24px 23px 32px;
  position: relative;
  transition: all 0.5s;
}

.search-data-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.history-text {
  color: #1b2653;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.75;
  text-align: left;
  margin: 0;
}
.saved-card-date {
  color: #1b2653;
  -webkit-column-gap: 5px;
  column-gap: 5px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.75;
}

span.diamond-date-td {
  color: #1b2653;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.75;
}

.saved-card-img img {
  height: 72px;
  object-fit: contain;
  width: 100%;
  transform: scaleY(1.15);
}

a .search-history .new-header {
  padding: 0px;
  margin: 0px 0px 40px;
  /* margin-bottom: 20px; */
}

.search-history .back-contain-btn {
  padding: 0px 15px 0px 0px;
}

/* .diamond-data-boxes .new-header {
    padding: 10px 80px 0px 62px;

    } */

/* @media screen and (max-width: 1024px) {
  .search-history {
    max-width: 728px;
    width: 100%;
    margin: auto;
  }
} */
@media screen and (max-width: 768px) {
  .search-history {
    max-width: 726px;
    width: 100%;
    margin:0px auto 20px;
  }

  .saved-card-img img{
    /* height: 60px; */
    height: 90px;
  }
  .search-data {
    padding: 16px;
  }
  .search-history h3 {
    font-size: 22px;
    margin: 32px 0px;
  }
  .history-text,
  span.diamond-date-td {
    /* font-size: 12px; */
  }

  .history-text, span.diamond-date-td {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .search-history .new-header {
    margin: 10px 0px;
  }
}


@media screen and (max-width: 425px) {
  .search-history {
    max-width: 728px;
    width: 100%;
    margin-bottom: 0px auto 20px;
    padding: 0px 20px;
  }
}
